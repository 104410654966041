import React from 'react';
import styled from 'styled-components';
import { graphql, PageProps } from 'gatsby';

import * as Base from 'src/page-styles/base.styled';
import { TemplateBody } from 'src/page-styles/template.styled';

import Layout from 'src/Layout';

import { FadeIn } from 'src/Components/CallToAction';
import Container from 'src/Components/ContentContainer';
import ToTop from 'src/Components/ToTop';
import SEO from 'src/Components/SEO';

const Attributes = styled.div`
  color: ${({ theme }) => theme.grey400};
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
`;

interface Props {
  title: string;
  employment: string[];
  display_image?: {
    publicURL: string;
  };
  job_location: string[];
  citizenship: string;
  html: string;
  fileName: string;
  site: {
    siteMetadata: {
      siteUrl?: string;
    };
  };
}

export const CareerTemplate: React.FunctionComponent<Props> = ({
  title,
  employment,
  job_location,
  citizenship,
  html,
  fileName,
  site,
  display_image,
}: Props) => {
  let img_url = `${site.siteMetadata?.siteUrl}${display_image?.publicURL}`;
  if (img_url.includes('undefined')) {
    img_url = 'https://assets.saberastro.com/img/Hiring_AU_US.jpg';
  }
  return (
    <>
      <SEO
        title={title}
        description={employment.join('/')}
        url={`${site.siteMetadata?.siteUrl}/positions/${fileName}`}
        image_url={img_url}
      />
      <Layout>
        <ToTop />
        <Container.Dark data-cy="career-template">
          <Base.MenuGap />
          <Base.Heading>{title}</Base.Heading>
          <Base.Text>
            <Attributes>{employment.join('/')}</Attributes>
          </Base.Text>
          <Base.Text>
            <Attributes>{job_location.join('/')}</Attributes>
          </Base.Text>
          <Base.Text>
            <Attributes>{citizenship}</Attributes>
          </Base.Text>
          <TemplateBody dangerouslySetInnerHTML={{ __html: html }} />
        </Container.Dark>
        <FadeIn
          header="For more positions!"
          linkText="Click here"
          to="/careers/"
        />
      </Layout>
    </>
  );
};

interface CareerData {
  markdownRemark: {
    frontmatter: {
      title: string;
      employment: string[];
      job_location: string[];
      citizenship: string;
      display_image?: {
        publicURL: string;
      };
    };
    html: string;
    parent: {
      name: string;
    };
  };
  site: {
    siteMetadata: {
      siteUrl?: string;
    };
  };
}

export default function Template({ data }: PageProps): JSX.Element {
  const { markdownRemark, site } = data as CareerData;
  const { frontmatter, html, parent } = markdownRemark;

  return (
    <CareerTemplate
      {...frontmatter}
      html={html}
      fileName={parent.name}
      site={site}
    />
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        employment
        job_location
        citizenship
        display_image {
          publicURL
        }
      }
      html
      parent {
        ... on File {
          name
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
